<template>
   <div class="w-260 px-2 text-center">
      <img class="rounded-circle" :src="depoimento.imagem == null ? '' : depoimento.imagem" width="164" @error="imageError" />
      <h1 class="font-15 mt-3 mb-2">{{ depoimento.empresa == null ? 'Não informado' : depoimento.empresa }}</h1>
      <p class="limitador-5">{{ depoimento.descricao == null ? 'Sem descrição' : depoimento.descricao }}</p>
   </div>
</template>

<script>

export default {
   name: 'Depoimento',
   props: ['depoimento'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
   object-fit: cover;
   aspect-ratio: 1/1;
}

</style>